/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';
import Img from 'gatsby-image';
import ReactHtmlParser from 'react-html-parser';
import HelmutFXsvg from '../../static/assets/HelmutFX.svg';
import HelmutHKlogo from '../../static/assets/HelmutHK.svg';
import HelmutCOlogo from '../../static/assets/HelmutCO.svg';
import HelmutIOlogo from '../../static/assets/HelmutIO.svg';
import ArrowRight from '../../static/assets/arrow-right.svg';
import ArrowLeft from '../../static/assets/arrow-left.svg';
import FxCoGrid from '../../static/assets/Grid_Hco-Hfx.svg';
import IoHkGrid from '../../static/assets/Grid_Hhk-Hio.svg';
import getRoute from '../helpers/getRoute';

function HexagonStack({
  title, hexagonItems, hexagonBgImage, lang,
}) {
  const [selected, setSelected] = useState(hexagonItems[0]);
  const variants = {
    fx: { x: 90, opacity: [0, 1] },
    io: { x: -50, opacity: [0, 1] },
    co: { x: 50, opacity: [0, 1] },
    hk: { x: -90, opacity: [0, 1] },
  };

  const spring = {
    ease: 'easeInOut',
    duration: 0.15,
  };
  const handleNext = () => {
    if (hexagonItems[hexagonItems.indexOf(selected) + 1]) {
      setSelected(hexagonItems[hexagonItems.indexOf(selected) + 1]);
    } else {
      setSelected(hexagonItems[0]);
    }
  };
  const handlePrev = () => {
    if (hexagonItems[hexagonItems.indexOf(selected) - 1]) {
      setSelected(hexagonItems[hexagonItems.indexOf(selected) - 1]);
    } else {
      setSelected(hexagonItems[hexagonItems.length - 1]);
    }
  };
  return (
    <div className="hexagon-component-warpper">
      <h2>{title}</h2>
      <>
        <motion.div
          className={`product-dsc ${
            (selected.title.includes('CO') || selected.title.includes('HK'))
            && 'reverse'
          }`}
          animate={
            selected.title.includes('FX')
              ? 'fx'
              : selected.title.includes('IO')
                ? 'io'
                : selected.title.includes('CO')
                  ? 'co'
                  : selected.title.includes('HK')
                    ? 'hk'
                    : ''
          }
          variants={variants}
          transition={spring}
        >
          <div className="main-dsc">
            <h3
              className="title"
              style={{
                color: selected.title.includes('IO')
                  ? '#9E90FF'
                  : selected.title.includes('CO')
                    ? '#FF6F01'
                    : selected.title.includes('HK')
                      ? ' #FF3333'
                      : '',
              }}
            >
              {ReactHtmlParser(selected.title)}
            </h3>
            <h3 className="product-subtitle">{selected.subtitle}</h3>
            <Link
              to={getRoute(
                lang,
                selected.link
                  .split('/')
                  .slice(selected.link.includes('/en') ? 4 : 3)
                  .join('/'),
              )}
              style={{
                borderColor: selected.title.includes('IO')
                  ? '#9E90FF'
                  : selected.title.includes('CO')
                    ? '#FF6F01'
                    : selected.title.includes('HK')
                      ? ' #FF3333'
                      : '',
              }}
              className="more-button"
            >
              {hexagonItems[0].button_label}
            </Link>
          </div>
          <p className="product-dsc-text">
            {ReactHtmlParser(selected.description)}
          </p>
          <div className="mobile-hex-nav">
            <span className="icon-warpper" onClick={handlePrev}>
              <ArrowLeft />
            </span>
            <Link
              to={getRoute(
                lang,
                selected.link
                  .split('/')
                  .slice(selected.link.includes('/en') ? 4 : 3)
                  .join('/'),
              )}
              style={{
                borderColor: selected.title.includes('IO')
                  ? '#9E90FF'
                  : selected.title.includes('CO')
                    ? '#FF6F01'
                    : selected.title.includes('HK')
                      ? ' #FF3333'
                      : '',
              }}
              className="more-button"
            >
              {hexagonItems[0].button_label}
            </Link>
            <span className="icon-warpper" onClick={handleNext}>
              <ArrowRight />
            </span>
          </div>
        </motion.div>
      </>
      <div className="grid-warpper">
        <AnimatePresence exitBeforeEnter>
          {selected.title.includes('FX') ? (
            <motion.div
              key="fx"
              initial={{ scale: 0, originY: 1, originX: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={spring}
              style={{ x: '85%' }}
              className="grid-item"
            >
              <FxCoGrid />
            </motion.div>
          ) : selected.title.includes('IO') ? (
            <motion.div
              key="io"
              initial={{ scale: 0, originY: 1, originX: 1 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              style={{ x: '35%' }}
              transition={spring}
              className=" grid-item"
            >
              <IoHkGrid />
            </motion.div>
          ) : selected.title.includes('CO') ? (
            <motion.div
              key="co"
              initial={{ scale: 0, originY: 1, originX: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={spring}
              style={{ x: '305%' }}
              className=" grid-item"
            >
              <FxCoGrid />
            </motion.div>
          ) : selected.title.includes('HK') ? (
            <motion.div
              key="hk"
              initial={{ scale: 0, originY: 1, originX: 1 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={spring}
              style={{ x: '258%' }}
              className="grid-item"
            >
              <IoHkGrid />
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
      <div className="hexagon-stack-warpper">
        <Link
          className={`${
            selected.title.includes('FX') ? 'active' : ''
          } hex-item`}
          to={hexagonItems[0].link}
        >
          <motion.div
            id="fx"
            whileHover={{ scale: 1.2, zIndex: 1 }}
            onMouseEnter={() => setSelected(hexagonItems[0])}
            transition={spring}
          >
            <HelmutFXsvg />
          </motion.div>
        </Link>
        <Link
          className={`${
            selected.title.includes('IO') ? 'active' : ''
          } hex-item`}
          to={hexagonItems[1].link}
        >
          <motion.div
            id="io"
            whileHover={{ scale: 1.2, zIndex: 1 }}
            onMouseEnter={() => setSelected(hexagonItems[1])}
            transition={spring}
          >
            <HelmutIOlogo />
          </motion.div>
        </Link>
        <Link
          className={`${
            selected.title.includes('CO') ? 'active' : ''
          } hex-item`}
          to={hexagonItems[2].link}
        >
          <motion.div
            id="co"
            whileHover={{ scale: 1.2, zIndex: 1 }}
            onMouseEnter={() => setSelected(hexagonItems[2])}
            transition={spring}
          >
            <HelmutCOlogo />
          </motion.div>
        </Link>
        <Link
          className={`${
            selected.title.includes('HK') ? 'active' : ''
          } hex-item`}
          to={hexagonItems[3].link}
        >
          <motion.div
            id="hk"
            whileHover={{ scale: 1.2, zIndex: 1 }}
            onMouseEnter={() => setSelected(hexagonItems[3])}
            transition={spring}
          >
            <HelmutHKlogo />
          </motion.div>
        </Link>
      </div>
      <Img className="hex-bg-img" fixed={hexagonBgImage} />
    </div>
  );
}

export default HexagonStack;
