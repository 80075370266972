/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropType from 'prop-types';
import Fade from 'react-reveal/Fade';

const LogosGrid = ({ logos, section_subtitle, section_title }) => (
  <div className="grid-wrapper">
    <div className="grid-container">

      <h2>{section_title}</h2>
      <p>{section_subtitle}</p>
      { (logos && Array.isArray(logos))
        ? (

          <div className="grid-logo">
            {logos.map((logo, index) => (

              <Fade key={index} bottom delay={50 * index}>
                <div key={logo.image.id} className="icons">
                  <div className="icons-inner">
                    <img src={logo.image.localFile.childImageSharp.fluid.src} alt="logo icon" className="iconImage" />
                  </div>
                </div>
              </Fade>
            ))}
          </div>

        )
        : <p>nothing</p>}
    </div>
  </div>
);

LogosGrid.propTypes = {
  logos: PropType.array.isRequired,
  // logos: PropType.shape({
  //   image: PropType.shape({
  //     id: PropType.string.isRequired,
  //     localFile: PropType.shape({
  //       childImageSharp: PropType.shape({
  //         fluid: PropType.shape({
  //           src: PropType.string.isRequired,
  //         }).isRequired,
  //       }).isRequired,
  //     }).isRequired,
  //   }).isRequired,
  // }).isRequired,

};

export default LogosGrid;
