import React from 'react';
import PropType from 'prop-types';
import { Link } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import getRoute from '../helpers/getRoute';

const ParentsLogo = ({
  imagesLogoGrid, title_logo_grid, subtitle_logo_grid, lang,

}) => (
  <div className="logos-wrapper"> 

    <h2>{title_logo_grid}</h2>
    <p className="subtitle">{subtitle_logo_grid}</p> 

    { (imagesLogoGrid && Array.isArray(imagesLogoGrid))
      ? (
        <div className="grid-logo">
          {imagesLogoGrid.map((listItem) => {
            return (
              <div key={listItem.image.id} className="icons">
                <div className="icon-inner">
                  <img src={listItem.image.localFile.childImageSharp.fluid.src} alt={listItem.title} className="iconImage" />
                  <div className="content">
                    <h5>{ReactHtmlParser(listItem.title)}</h5>
                    <p className="descriptionIcon">{ReactHtmlParser(listItem.description)}</p>
                    <Link to={getRoute(lang, listItem.link)} className="btn btn-primary">{listItem.button_label}</Link>
                  </div>

                  <div className="gradient-border" />
                </div>
              </div>
            );
          })}
        </div>
      )
      : <p>nothing</p>}
  </div>
);
ParentsLogo.propTypes = {
  title_logo_grid: PropType.string.isRequired,
  subtitle_logo_grid: PropType.string.isRequired,
  // imagesLogoGrid: PropType.array.isRequired,
  // imagesLogoGrid: PropType.shape({
  //   description: PropType.string.isRequired,
  //   image: PropType.shape({
  //     id: PropType.string.isRequired,
  //     localFile: PropType.shape({
  //       childImageSharp: PropType.shape({
  //         fluid: PropType.shape({
  //           src: PropType.string.isRequired,
  //         }).isRequired,
  //       }).isRequired,
  //     }).isRequired,
  //   }).isRequired,
  // }).isRequired,

};

export default ParentsLogo;
