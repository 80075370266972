import React from "react";
import { graphql } from "gatsby";
import CoverImage from "../components/coverImage";
import ArticlewithCTA from "../components/articleWithCTA";
import SliderComponent from "../components/slider";
import ImagesGrid from "../components/imagesGrid";
import LogosGrid from "../components/logosGrid";
import ParentsLogo from "../components/parentsLogoGrid";
import ArticleWithImageAndCards from "../components/articleWithImageAndCards";
import HexagonStack from "../components/hexagonStack";

const HomepageTemplate = props => {
  // const { transitionStatus, uri } = props;
  // const { setTransitionProps } = useContext(Context);

  // useEffect(() => {
  //   setTransitionProps({ transitionStatus, uri });
  // }, [transitionStatus]);

  const {
    data: {
      wordpressPage: {
        acf: {
          first_main_image: {
            localFile: {
              childImageSharp: { fluid: firstCoverImage },
            },
          },
          first_background_gradient_container: {
            button_label,
            button_url,
            open_new_tab,
            description,
            title,
            background_image: {
              localFile: {
                childImageSharp: { fluid: coverImageArticlewithCTA },
              },
            },
          },

          slider_section: {
            static_slider_section_title,
            static_image_slider,
            static_slider_background_image: {
              localFile: {
                childImageSharp: { fluid: sliderFluid },
              },
            },
          },

          second_main_image: {
            localFile: {
              childImageSharp: { fluid: secondCoverImage },
            },
          },
          second_background_gradient_container: {
            title: presantationSectionTitle,
            description: presantationSectionDescription,
            slider: sliderItems,
            background_image: {
              localFile: {
                childImageSharp: { fluid: presantationSectionCover },
              },
            },
          },
          grid_3x_with_images_container: {
            image_1: {
              localFile: {
                childImageSharp: { fluid: firstImageGrid },
              },
            },
            image_2: {
              localFile: {
                childImageSharp: { fluid: secondImageGrid },
              },
            },
            image_3: {
              localFile: {
                childImageSharp: { fluid: thirdImageGrid },
              },
            },
          },
          stacks_of_hexagons_container: {
            section_title: hexangonStackTitle,
            item_hexagon,
            section_image: {
              localFile: {
                childImageSharp: { fixed: hexagonBgImage },
              },
            },
          },
          grid_5x_with_logos_container: {
            section_subtitle,
            section_title,
            logos,
          },

          grid_2x_with_logos_container: {
            section_subtitle: subtitle_logo_grid,
            section_title: title_logo_grid,
            logos: imagesLogoGrid,
          },

          section_with_cta_container: {
            link: link_CTA,
            section_subtitle: subtitleCTA,
            section_title: titleCTA,
            button_label: button_labelCTA,
          },
        },
      },
    },
    pathContext,
  } = props;
  return (
    <div>
      <CoverImage fluid={firstCoverImage} />
      <ArticlewithCTA
        title={title}
        fluid={coverImageArticlewithCTA}
        text={description}
        button_url={button_url}
        button_label={button_label}
        open_new_tab={open_new_tab}
      />
      <SliderComponent
        static_slider_section_title={static_slider_section_title}
        static_image_slider={static_image_slider}
        fluid={sliderFluid}
      />
      <CoverImage fluid={secondCoverImage} />
      <ArticleWithImageAndCards
        title={presantationSectionTitle}
        description={presantationSectionDescription}
        sliderItems={sliderItems}
        coverImg={presantationSectionCover}
      />
      <HexagonStack
        hexagonItems={item_hexagon}
        title={hexangonStackTitle}
        hexagonBgImage={hexagonBgImage}
        lang={pathContext.lang}
      />
      <div style={{ backgroundColor: "#2a2a2a" }}>
        <ImagesGrid
          firstImageGrid={firstImageGrid}
          secondImageGrid={secondImageGrid}
          thirdImageGrid={thirdImageGrid}
        />
      </div>
      <LogosGrid
        section_subtitle={section_subtitle}
        section_title={section_title}
        logos={logos}
      />
      <ParentsLogo
        subtitle_logo_grid={subtitle_logo_grid}
        title_logo_grid={title_logo_grid}
        imagesLogoGrid={imagesLogoGrid}
        lang={pathContext.lang}
      />
      <div className="section-with-cta">
        <h2>{titleCTA}</h2>
        <p>{subtitleCTA}</p>
        <a className="btn btn-primary" href={link_CTA}>
          {button_labelCTA}
        </a>
      </div>
    </div>
  );
};

export const query = graphql`
  query($lang: String) {
    wordpressPage(
      slug: { eq: "homepage" }
      wpml_current_locale: { eq: $lang }
    ) {
      title
      template
      path
      yoast_head
      acf {
        first_main_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        first_background_gradient_container {
          button_label
          button_url
          open_new_tab
          description
          title
          background_image {
            localFile {
              childImageSharp {
                fluid(maxHeight: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                  originalImg
                }
              }
            }
          }
        }

        slider_section {
          static_slider_section_title
          static_image_slider {
            button_label
            button_url
            color_overlay
            description
            number
          }
          static_slider_background_image {
            localFile {
              childImageSharp {
                fluid(maxHeight: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }

        second_main_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        second_background_gradient_container {
          title
          description
          background_image {
            localFile {
              childImageSharp {
                fluid(maxHeight: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          slider {
            title
            text
            image {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 800, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        grid_3x_with_images_container {
          image_1 {
            localFile {
              childImageSharp {
                fluid(maxHeight: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image_2 {
            localFile {
              childImageSharp {
                fluid(maxHeight: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image_3 {
            localFile {
              childImageSharp {
                fluid(maxHeight: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        stacks_of_hexagons_container {
          section_title
          item_hexagon {
            title
            subtitle
            link
            description
            button_label
          }
          section_image {
            localFile {
              childImageSharp {
                fixed(width: 1440, height: 1002, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        grid_5x_with_logos_container {
          section_subtitle
          section_title
          logos {
            image {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 800, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              id
            }
          }
        }
        grid_2x_with_logos_container {
          section_subtitle
          section_title
          logos {
            description
            link
            title
            button_label
            image {
              id
              localFile {
                childImageSharp {
                  fluid(maxHeight: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }

        section_with_cta_container {
          link
          section_subtitle
          section_title
          button_label
        }
      }
    }
    wordpressAcfOptions {
      options {
        kontakt {
          de_label
          en_label
        }
        produkte {
          de_label
          en_label
        }
        referenzen {
          de_label
          en_label
        }
        sprache {
          de_label
          en_label
        }
        copyright_text
        contact_icons_footer {
          email
          phone
        }
        bottom_footer_right {
          name
          english_name
          url
        }
        bottom_footer_left {
          name
          english_name
          url
        }
      }
    }
  }
`;
export default HomepageTemplate;
