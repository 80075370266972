import React from 'react';
import Img from 'gatsby-image';
import Fade from 'react-reveal/Fade';
import PropType from 'prop-types';

const ImagesGrid = ({ firstImageGrid, secondImageGrid, thirdImageGrid }) => (

  <div className="image-grid-wrapper">

    <div className="container">

      <div className="row">
        <Fade bottom delay={200}>
          <div className="col-md-4">
            <Img fluid={firstImageGrid} /> 
          </div>
        </Fade>
        <Fade bottom delay={400}>
          <div className="col-md-4">
            <Img fluid={secondImageGrid} />
          </div>
        </Fade>
        <Fade bottom delay={600}>
          <div className="col-md-4">
            <Img fluid={thirdImageGrid} />
          </div>
        </Fade>
      </div>

    </div>

  </div>
);

ImagesGrid.PropType = {
  firstImageGrid: PropType.shape({
    src: PropType.string,
    srcSet: PropType.string,
  }).isRequired,
  secondImageGrid: PropType.shape({
    src: PropType.string,
    srcSet: PropType.string,
  }).isRequired,
  thirdImageGrid: PropType.shape({
    src: PropType.string,
    srcSet: PropType.string,
  }).isRequired,
};

export default ImagesGrid;
