import React, { Component } from "react";
import Fade from "react-reveal/Fade";
// import Rotate from 'react-reveal/Rotate';
import Slider from "react-slick";
import { useInView } from "react-intersection-observer";
import ReactHtmlParser from "react-html-parser";
import hexagon from "../images/Slider-Number-Container.png";
import SliderNextArrow from "./sliderNextArrow";
import SliderPrevArrow from "./sliderPrevArrow";
import { v4 as uuid } from "uuid";

function SliderComponent(props) {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     nav1: null,
  //     nav2: null,
  //   };
  // }

  const [ref, inView] = useInView();

  const [nav1, setNav1] = React.useState();
  const [nav2, setNav2] = React.useState();
  // const slider1Ref = React.useRef();
  // const slider2Ref = React.useRef();

  // React.useEffect(() => {
  //   setNav1(slider1Ref); setNav2(slider2Ref);
  // }, []);

  const { static_slider_section_title, static_image_slider, fluid } = props;
  return (
    <div ref={ref} className="slider-container">
      <div className="slider-title-wrapper">
        <h2 className="staic-slider-title">
          {ReactHtmlParser(static_slider_section_title)}
        </h2>
      </div>
      <div
        className="sliders-wrapper"
        style={{ backgroundImage: `url(${fluid.src})` }}
      >
        {inView ? (
          <>
            <Slider
              fade
              infinite
              arrows={false}
              swipe={false}
              asNavFor={nav2}
              ref={slider1 => setNav1(slider1)}
              slidesToShow={1}
              className="slider1"
            >
              {static_image_slider &&
                Array.isArray(static_image_slider) &&
                static_image_slider.map(slider => (
                  <div key={uuid()}>
                    <div
                      className="first-slider"
                      style={{
                        backgroundColor: `${slider.color_overlay}`,
                        opacity: "0.3",
                      }}
                    />
                  </div>
                ))}
            </Slider>
            <Slider
              speed="800"
              infinite="true"
              swipe="false"
              autoplay
              autoplaySpeed={4000}
              ref={slider2 => setNav2(slider2)}
              asNavFor={nav1}
              slidesToShow={1}
              swipeToSlide
              focusOnSelect
              className="slider2" 
              nextArrow={<SliderNextArrow title="Next" uuid={uuid()} />}
              prevArrow={<SliderPrevArrow title="Previous" uuid={uuid()} />}
            >
              {static_image_slider &&
                Array.isArray(static_image_slider) &&
                static_image_slider.map(slider2 => (
                  <div key={uuid()} className="second-slider-item">
                    <div className="slider2-content container">
                      {slider2.number && (
                        <>
                          <img src={hexagon} alt="hexagon" />
                          <span>{ReactHtmlParser(slider2.number)}</span>
                        </>
                      )}

                      <Fade bottom>
                        <>
                          <p className="description" key="1">
                            {slider2.description}
                          </p>
                          {slider2.button_url && slider2.button_url !== "" && (
                            <div key="2" className="more">
                              <a href={slider2.button_url}>
                                {" "}
                                {slider2.button_label}{" "}
                              </a>
                            </div>
                          )}
                        </>
                      </Fade>
                    </div>
                  </div>
                ))}
            </Slider>
          </>
        ) : <div className="slider-container"></div>}
      </div>
    </div>
  );
}

export default SliderComponent;
