/* eslint-disable no-nested-ternary */
import React from 'react';
import Img from 'gatsby-image';
import Fade from 'react-reveal/Fade';
import PropType from 'prop-types';
import { Parallax } from 'react-scroll-parallax';
import ReactHtmlParser from 'react-html-parser';
import ArrowRight from '../../static/assets/circle-arrow-right.svg';

const ArticlewithCTA = ({
  title,
  fluid,
  text,
  button_label,
  button_url,
  open_new_tab,
  color,
  column = false,
  style = {},
  downloadFile = null,
}) => (
  <div
    style={{ backgroundColor: color, ...style }}
    className="bg-shadow-article"
  >
    {fluid ? (
      <Parallax
        styleOuter={{ opacity: color ? 0.5 : 1 }}
        styleInner={{
          height: '140%',
        }}
        tagOuter="bg-image"
        y={[-45, 30]}
      >
        <Img fluid={fluid} />
      </Parallax>
    ) : null}
    <div style={style} className="copy-wrapper-article">
      <Fade bottom>
        <h2 className="title">{ReactHtmlParser(title)}</h2>
        <div
          style={column ? { columnCount: 1 } : {}}
          className="text-description"
        >
          {text}
        </div>
        {button_label && button_url && open_new_tab === true ? (
          <a href={button_url} target="_blank" rel="noreferrer">
            <p className="videoPresentation">
              {' '}
              {button_label}
              <span>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="play-circle"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="svg-inline--fa fa-play-circle fa-w-16 fa-2x"
                >
                  <path
                    fill="currentColor"
                    d="M371.7 238l-176-107c-15.8-8.8-35.7 2.5-35.7 21v208c0 18.4 19.8 29.8 35.7 21l176-101c16.4-9.1 16.4-32.8 0-42zM504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256z"
                    className=""
                  />
                </svg>
              </span>
            </p>
          </a>
        ) : downloadFile
          ? (
            <a href={downloadFile.link} target="_blank" rel="noreferrer" download>
              <p className="videoPresentation">
                {' '}
                {ReactHtmlParser(downloadFile.presentation_file_label)}
                {downloadFile.presentation_file_label && <ArrowRight />}
              </p>
            </a>
          )
          : ''}

      </Fade>
    </div>
  </div>
);

ArticlewithCTA.defaultProps = {
  button_label: '',
  button_url: '',
  fluid: null,
  color: null,
};

ArticlewithCTA.propTypes = {
  title: PropType.string.isRequired,
  text: PropType.string.isRequired,
  button_label: PropType.string,
  button_url: PropType.string,
  fluid: PropType.shape({
    src: PropType.string,
    srcSet: PropType.string,
  }),
  color: PropType.string,
};

export default ArticlewithCTA;
