/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Img from 'gatsby-image';
import { motion, AnimatePresence } from 'framer-motion';
import { Parallax } from 'react-scroll-parallax';
import ReactHtmlParser from 'react-html-parser';
import ArrowRight from '../../static/assets/arrow-right.svg';
import ArrowLeft from '../../static/assets/arrow-left.svg';

function ArticleWithImageAndCards({
  title,
  description,
  sliderItems, 
  coverImg,
}) {
  const [slideIndex, setSlideIndex] = useState(0);
  const slideVairants = {
    initial: { opacity: 0, scale: 0.6, x: 300 },
    enter: {
      opacity: 1,
      scale: 1,
      x: 0,
      transition: { ease: 'easeIn', delay: 0.1, duration: 0.35 },
    },
    exit: {
      opacity: 0,
      scale: 0.6,
      x: -300,
      transition: { ease: 'easeOut', duration: 0.35 },
    },
  };
  const handleNext = () => {
    if (slideIndex + 1 <= sliderItems.length - 1) {
      setSlideIndex(slideIndex + 1);
    } else {
      setSlideIndex(0);
    }
  };
  const handlePrev = () => {
    if (slideIndex - 1 >= 0) {
      setSlideIndex(slideIndex - 1);
    } else {
      setSlideIndex(sliderItems.length - 1);
    }
  };

  return (
    <div className="article-with-image-and-cards">
      <Img className="bg-img" fluid={coverImg} />
      <div className="content-warpper">
        <Parallax
          className="custom-class"
          y={[20, -20]}
          styleOuter={{}}
          tagOuter="figure" 
        >
          <div className="text-section">
            <h2>{ReactHtmlParser(title)}</h2>
            <div className="description">
              <p>{ReactHtmlParser(description)}</p>
            </div>
          </div>
        </Parallax>
        <div className="card-images-warpper">
          <AnimatePresence exitBeforeEnter>
            {sliderItems.map((item, index) => (slideIndex === index ? (
              <motion.div
                key={index}
                className="slide-img-warpper"
                initial="initial"
                animate="enter"
                transition={{ ease: 'easeIn' }}
                variants={slideVairants}
                exit="exit"
              >
                <img
                  className="slide-img"
                  src={item.image.localFile.childImageSharp.fluid.src}
                  alt=""
                />
              </motion.div>
            ) : null))}
          </AnimatePresence>
        </div>
        <div className="cards-warpper">
          {sliderItems.map((item, index) => (
            <div
              key={index}
              className={`_card ${slideIndex === index ? 'active' : ''}`}
              onClick={() => setSlideIndex(index)}
              onKeyDown={() => setSlideIndex(index)}
              role="textbox"
              tabIndex="-2"
            >
              <div className="gradient-border" />
              <div className="card-content">
                <h3>{ReactHtmlParser(item.title)}</h3>
                <p>{ReactHtmlParser(item.text)}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="arrow-container">
          <div
            onClick={handlePrev}
            onKeyDown={handlePrev}
            role="button"
            tabIndex={0}
            className="icon-warpper"
          >
            <ArrowLeft />
          </div>
          <div
            onClick={handleNext}
            onKeyDown={handleNext}
            role="button"
            tabIndex={0}
            className="icon-warpper"
          >
            <ArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArticleWithImageAndCards;
